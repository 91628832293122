import * as React from "react";
import Credit from "../img/Credit.png"

// import instagram from "../img/social/instagram.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="section" style={{display: "flex", justifyContent: "center"}}>            
          {/* <a title="credits" href="https://tiffbouchard.com" target="_blank">
            <img src={Credit} style={{height: "20px"}}/>
          </a> */}
      </footer>
    );
  }
};

export default Footer;
