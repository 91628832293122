import React from "react";
import { Link } from "gatsby";
import Logo1 from "../img/itzalogo1.png";
import { Squash as Hamburger } from 'hamburger-react'


const Navbar = () => {
  const [isOpen, setOpen] = React.useState(false)

  const [isShowing, setShowing] = React.useState(false)

    return (
      <nav
        className="navbar"
        role="navigation"
        aria-label="main-navigation"
      >


          <div
            id="navMenu"
            className="navbar-menu"
          >
            <div className="nav-items">
              <Link className="navbar-item" to="/about">
                About
              </Link>
              <div class="dropdown">
                <button class="navbar-item dropdown-btn">Work</button>
                <div class="dropdown-content">
                  <Link className="navbar-sub-item" to="/modelling">Modelling</Link>
                  <Link className="navbar-sub-item" to="/styling">Styling</Link>
                </div>
              </div>
              {/* <Link className="navbar-item" to="/work">
                Work
              </Link> */}
              <Link className="navbar-item" to="/digitals">
                Digitals
              </Link>
            </div>

             {/* Hamburger menu */}
          <div
              className={isOpen ? "navbar-burger burger open" : "navbar-burger burger"}
              data-target="navMenu"
              role="menuitem"
              tabIndex={0}
            >
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
          </div>

          <div className={isOpen ? "mobile-menu open" : "mobile-menu"}>
              <Link className="navbar-item" to="/about">
                About
              </Link>
              {/* <Link className="navbar-item" to="/work">
                Work
              </Link> */}
              <div class="dropdown">
                <button class="navbar-item dropdown-btn" onClick={() => setShowing(!isShowing)}>Work</button>
                <div className={isShowing ? "mobile-dropdown-content open" : "mobile-dropdown-content"} >
                  <Link className="navbar-sub-item" to="/modelling">Modelling</Link>
                  <Link className="navbar-sub-item" to="/styling">Styling</Link>
                </div>
              </div>
              <Link className="navbar-item" to="/digitals">
                Digitals
              </Link>
          </div>

          <div>
            <Link to="/" ctitle="Logo">
              <img
                src={Logo1}
                alt="Logo"
                className="logo"
              />
            </Link>

          </div>




      </nav>
    );
  };

export default Navbar;




// @media only screen and (max-width: 1023px)
//   .navbar
//     margin-top: 20px
//   .section
//     padding-left: 15px

// @media only screen and (max-width: 768px) 
//   .column.is-4 .image
//     padding: 0 25px 15px 25px
//   .section
//     padding: 0 25px
//   .navbar 
//     padding: 0 15px
//   footer.section
//     padding-left: 15px
//     padding-bottom: 12px
//   footer img 
//     font-size: 17px
//   .work
//     padding-left: 4px

// @media only screen and (min-width: 768px) 
//   .section
//     padding: 0 25px
//   .navbar 
//     padding: 0 15px
//   footer.section
//     padding-left: 15px
//     padding-bottom: 12px
//   .work
//     padding-left: 4px